const floors = [
  'Perle',
  'FLINTA*Floor',
  'Healing Area',
  '1000 Meter',
].sort()

const floorDescriptions = {
  de: {
    Perle: 'Kurze Beschreibung.',
    FLINTAFloor:
      'Kurze Beschreibung',
    HealingArea: `Kurze Beschreibung`,
    Meter: `Kurze Beschreibung`,
  },
  en: {
     Perle: 'Kurze Beschreibung.',
    FLINTAFloor:
      'Kurze Beschreibung',
    HealingArea: `Kurze Beschreibung`,
    Meter: `Kurze Beschreibung.`,
  },
}

const types = [
  'DJ',
  'Band/Live',
  'Talk',
  'DIY-Angebot',
  'Performance',
  'Workshop',
  'others',
]

const days = ['Fr', 'Sa', 'So']

module.exports = {
  floors,
  days,
  types,
  floorDescriptions,
}
