import { Map } from 'maplibre-gl'
import { FUSION_MAP_BOUNDS } from './constants/map'
import { FsnLayerId } from './models/map'

import festivalLayer from '../images/map-layers/festival.webp'

export const FESTIVAL: FsnLayerId = 'festival'
export const FESTIVAL_SRC = `${FESTIVAL}-src`

export const addLayers = (map: Map) => {
  map.addSource(FESTIVAL_SRC, {
    type: 'image',
    url: festivalLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: FESTIVAL,
    source: FESTIVAL_SRC,
    type: 'raster',
  })



}
