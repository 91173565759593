import { Coordinate, FsnCoord } from '../models/map'

export function isPointInBounds(
  point: Coordinate,
  bounds: {
    NE: FsnCoord
    SW: FsnCoord
  }
): boolean {
  const eastBound = point.longitude < bounds.NE[0]
  const westBound = point.longitude > bounds.SW[0]

  let inLong

  if (bounds.NE[0] < bounds.SW[0]) {
    inLong = eastBound || westBound
  } else {
    inLong = eastBound && westBound
  }

  const inLat = point.latitude > bounds.SW[1] && point.latitude < bounds.NE[1]

  return inLat && inLong
}
