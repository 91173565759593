import React from 'react'
import {
  Button,
  H1,
  Text,
  Page,
  Content,
  Bottom,
  Row,
  Message,
} from '../components'
import { useNavigate } from 'react-router-dom'

const Emergency = () => {
  const navigate = useNavigate()
  return (
    <Page>
      <Content>
        <H1>
          <Message id="EMERGENCY_HEADLINE_1" />
          <br />
        </H1>

        <Text>
          <Message id="EMERGENCY_MESSAGE_1" />
          <Message id="EMERGENCY_MESSAGE_2" />
        </Text>

        <H1>  
          <a href="tel:0398332740400">
            <Message id="EMERGENCY_NUMBER" />
          </a>
        </H1>
      </Content>
      <Bottom>
        <Row>
          <Button
            onClick={() => {
              navigate(-1)
            }}
          >
            <Message id="BUTTON_OK" />
          </Button>
        </Row>
      </Bottom>
    </Page>
  )
}

export default Emergency
