import { FC, useState } from 'react'
import { BottomArea } from '../BottomArea/BottomArea'
import { Message, Page } from '../components'
import { FsnModal } from '../components/modal/Modal'
import { MapComponent } from './MapComponent'
import { TENT_ICON_KEY } from './constants/map'

export const FestivalMap: FC = () => {
  const isTentUnset = !localStorage.getItem(TENT_ICON_KEY)
  const [isModalOpen, setIsModalOpen] = useState(isTentUnset)

  return (
    <Page withBackground>
      <MapComponent />
      <BottomArea
        showFilterButton={false}
        hasFilters={false}
        resetFilter={() => {}}
        gradientType="light"
      />
    </Page>
  )
}
