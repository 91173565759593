const palette = {
  primary: '#FFFF00',
  primaryDark: '#BD00FF',
  primaryLight: '#FFFF00',
  primaryMedium: '#39183D',
  secondary: '#FF0000',
  secondaryLight: '#000000',
  third: '#848283',
  fourth: '#BD00FF',
  black: '#000000',
}

const colors = {
  whiteDark: palette.secondaryLight,
  white: '#ffffff',

  backgroundDark: '#2b2b2b',
  backgroundBlack: palette.black,
  backgroundTransparent: '#252424cc',
  backgroundTransparent2: '#252424ee',

  text: '#252424',
  textInverted: '#FFFFFF',

  primary: palette.secondary,
  secondary: palette.secondary,

  filtersBackground: palette.primaryDark,

  highlight: palette.fourth,
  favorite: '#645556',
  favoriteLight: '#433839',

  plusButton: palette.secondary,
  plusButtonFavorite: palette.secondaryLight,

  scrollUpButton: '#c74544',
  scrollUpButtonActive: '#672222',

  timeHeader: {
    background: palette.primaryDark,
    border: palette.primaryLight,
  },

  Entry: {
    background: palette.black,
    // backgroundFavorite: palette.primaryDark,
    backgroundFavorite: '#39183D',
    border: palette.primary,
  },

  Search: {
    textColor: palette.primaryMedium,
    background: '#FBEEE4',
    closeIconBg: palette.secondaryLight,
    closeIconText: palette.secondary,
  },

  Filters: {
    titleBackground: palette.primaryDark,
    border: palette.primaryLight,
  },

  zIndex: {
    timeTable: 10,
    stickyStuff: 11,
    menu: 20,
  },

  error: {
    textColor: palette.secondary,
  },
}

export default colors

export { colors }

