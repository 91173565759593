import maplibregl, { Map } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useEffect, useRef, useState } from 'react'
import locator from '../images/locator.svg'
import north from '../images/north.svg'
import water from '../images/water.svg'

import { Message } from '../components'
import wc from '../images/wc.svg'
import classes from './Map.module.css'
import { MapButton } from './components/map-button/MapButton'
import {
  FUSION_CENTER,
  MAX_NE,
  MAX_SW,
  NE,
  SW,
} from './constants/map'
import { isPointInBounds } from './helpers/map'
import {addLayers } from './layers'
import { FsnLayerId } from './models/map'

const geolocator = new maplibregl.GeolocateControl({
  positionOptions: {
    enableHighAccuracy: true,
  },
  trackUserLocation: true,
})

const MAX_ZOOM = 15.6

export function MapComponent() {
  const mapContainer = useRef(null)

  let [userLocation, setUserLocation] = useState<{
    longitude: number
    latitude: number
  } | null>(null)

  let [userIsOutsideOfFsn, setUserIsOutsideOfFsn] = useState<boolean>(true)
  let [theMap, setTheMap] = useState<Map | null>(null)
  let [bearing, setBearing] = useState<number>(0)

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current!,
      style: { version: 8, sources: {}, layers: [] },
      center: FUSION_CENTER,
      maxBounds: [MAX_SW, MAX_NE],
      zoom: 13,
      minZoom: 12.8,
      maxZoom: MAX_ZOOM,
      pitchWithRotate: false,
      touchPitch: false,
    })
    map.addControl(geolocator)

    map.on('load', () => {
      setTheMap(map)
    })

    map.on('rotate', () => {
      setBearing(map.getBearing())
    })

    geolocator.once('geolocate', () => {
      map.fitBounds([SW, NE])
    })

    geolocator.on('geolocate', (data) => {
      const userLocation = data.coords
      if (isPointInBounds(userLocation, { NE, SW })) {
        setUserIsOutsideOfFsn(false)
      }

      setUserLocation(userLocation)
    })

    geolocator.on('outofmaxbounds', () => {
      setUserIsOutsideOfFsn(true)
    })

    return () => {
      if (map) {
        map.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (!theMap) return

    theMap.fitBounds([SW, NE])

    addLayers(theMap)

    geolocator.trigger()
  }, [theMap])

  const centerMe = () => {
    if (!userLocation) {
      geolocator.trigger()
      return
    }

    theMap!.flyTo({
      center: [userLocation.longitude, userLocation.latitude],
      zoom: MAX_ZOOM,
      speed: 2,
      curve: 1.42,
    })
  }


  
  const resetNorth = () => {
    if (!theMap) return
    theMap?.resetNorth()
  }


  return (
    <>

      {userIsOutsideOfFsn && (
        <div className={classes.info}>
          <Message id="OUTSIDE_OF_FUSION" />
        </div>
      )}

      <div ref={mapContainer} className={classes.map} />
    </>
  )
}
