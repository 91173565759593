import React, { ReactNode } from 'react'
import styled from 'styled-components'
import colors from './colors'
import { SubTitle1 } from './Typo'
import img from '../images/bg-pattern-23.png'

type Props = {
  title: ReactNode
}

const Title = styled.div`
  padding: 1.5rem 1rem;
  background-color: ${colors.primary};
  background: url(${img}), #BD00FF;
  background-blend-mode: soft-light, normal;
`

export const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Title>
      <SubTitle1>{title}</SubTitle1>
    </Title>
  )
}
